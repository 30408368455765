<template>
  <div class="login-container">
    <el-form class="login-box" ref="AccountForm" :model="account" :rules="loginRules" label-position="left">
      <div class="logo"><img src="/images/logo.jpg" /></div>
      <h3>{{ appName }}</h3>
      <el-form-item prop="username">
        <el-input v-model="account.username" :autofocus="true" style="width: 100%" type="text" placeholder="账号"
          prefix-icon="el-icon-user">
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="account.password" type="password" style="width: 100%" placeholder="密码"
          prefix-icon="el-icon-lock" show-password>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifycode">
        <el-input v-model="account.verifycode" type="text" style="width: 100%" placeholder="验证码"
          prefix-icon="el-icon-key">
        </el-input>
        <el-image v-if="codeUrl" @click.stop="initCode" class="code_img" :src="codeUrl" fit="fill"></el-image>
      </el-form-item>
      <el-form-item>
        <el-button native-type="submit" @click.native.prevent="handleLogin" :loading="logining" style="width: 100%"
          type="primary">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import appApi from "@/api/app";
export default {
  name: "login",
  data() {
    return {
      appName: "",
      account: {
        username: "",
        password: "",
        verifycode: "",
      },
      codeUrl: "",
      loginRules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        verifycode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      checked: true,
      logining: false,
    };
  },
  created() { },
  mounted() {
    this.appName = process.env.VUE_APP_NAME;
    this.initCode();
  },
  methods: {
    initCode() {
      this.account.verifycode = "";
      this.codeUrl = "/api/app/captcha?t=" + new Date().getTime();
    },
    handleLogin() {
      this.$refs.AccountForm.validate((valid) => {
        if (valid) {
          this.logining = true;
          appApi
            .login({
              username: this.account.username,
              password: this.account.password,
              verifycode: this.account.verifycode,
            })
            .then((res) => {
              window.sessionStorage.setItem("token", res.token);
              this.$store.commit("userInfo/name", res.user.name);
              this.$store.commit("userInfo/username", res.user.username);
              this.$store.commit("userInfo/powers", res.user.powers);
              this.$store.commit("menu/activeMenu", "");
              this.$store.commit("menu/menuList", res.menus);
              let path = "";
              if (res.menus[0].is_page) {
                path = res.menus[0].url;
              } else {
                path = res.menus[0].menus[0].url;
              }
              this.$router.push({
                path: path,
              });
            })
            .catch((res) => { })
            .finally(() => {
              this.logining = false;
              this.account.password = "";
              this.initCode();
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scope></style>
