const requestApi = require("@/lib/request");
//请求
module.exports = {
    //登录
    login(data) {
        return new Promise((resolve, reject) => {
            requestApi.commonRequest('/api/app/login', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    },
    //首页
    home() {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/home').then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    }
}